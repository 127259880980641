// Attendez que le DOM soit entièrement chargé
document.addEventListener("DOMContentLoaded", function () {
  // Sélectionner toutes les div avec la classe "elementor-carousel-image" incluses dans une div avec la classe "rea-product_slider"
  let elements = document.querySelectorAll(
    ".rea-product_slider .elementor-carousel-image"
  );

  elements.forEach((element) => {
    // Récupérer la valeur de l'attribut aria-label
    let ariaLabel = element.getAttribute("aria-label");

    // Créer un élément span pour afficher la valeur de l'attribut aria-label
    let span = document.createElement("span");
    span.textContent = ariaLabel;

    // Ajouter la classe CSS pour le style
    span.classList.add("aria-label-value");

    // Ajouter l'élément span en tant qu'enfant de l'élément actuel
    element.appendChild(span);
  });
});
