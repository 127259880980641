document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementById("devis_etagere_euro")) {
    let choice = document.getElementById("nombre_etageres_euro");
    let etagere01 = document.getElementById("etagere_euro_1");
    let etagere02 = document.getElementById("etagere_euro_2");
    let etagere03 = document.getElementById("etagere_euro_3");
    let etagere04 = document.getElementById("etagere_euro_4");
    let etagere05 = document.getElementById("etagere_euro_5");
    const alertQty = document.getElementById("alerte_quantite");
    choice.addEventListener("change", function () {
      // Récupérer la valeur sélectionnée
      var value = choice.value;

      checkValue(value);
    });
    function checkValue(choice) {
      // Switch sur la valeur sélectionnée pour effectuer différentes actions
      switch (choice) {
        case "1":
          console.log("Option 1 sélectionnée");
          etagere01.classList.remove("hidden");
          etagere02.classList.add("hidden");
          etagere03.classList.add("hidden");
          etagere04.classList.add("hidden");
          etagere05.classList.add("hidden");
          alertQty.classList.add("hidden");
          break;
        case "2":
          console.log("Option 2 sélectionnée");
          etagere01.classList.remove("hidden");
          etagere02.classList.remove("hidden");
          etagere03.classList.add("hidden");
          etagere04.classList.add("hidden");
          etagere05.classList.add("hidden");
          alertQty.classList.add("hidden");
          break;
        case "3":
          console.log("Option 3 sélectionnée");
          etagere01.classList.remove("hidden");
          etagere02.classList.remove("hidden");
          etagere03.classList.remove("hidden");
          etagere04.classList.add("hidden");
          etagere05.classList.add("hidden");
          alertQty.classList.add("hidden");
          break;
        case "4":
          console.log("Option 4 sélectionnée");
          etagere01.classList.remove("hidden");
          etagere02.classList.remove("hidden");
          etagere03.classList.remove("hidden");
          etagere04.classList.remove("hidden");
          etagere05.classList.add("hidden");
          alertQty.classList.add("hidden");
          break;
        case "5":
          console.log("Option 5 sélectionnée");
          etagere01.classList.remove("hidden");
          etagere02.classList.remove("hidden");
          etagere03.classList.remove("hidden");
          etagere04.classList.remove("hidden");
          etagere05.classList.remove("hidden");
          alertQty.classList.remove("hidden");
          break;

        default:
          // Action par défaut si la valeur ne correspond à aucun cas
          console.log("Option non reconnue");
          etagere01.classList.add("hidden");
          etagere02.classList.add("hidden");
          etagere03.classList.add("hidden");
          etagere04.classList.add("hidden");
          etagere05.classList.add("hidden");
          alertQty.classList.add("hidden");
      }
    }
  }
});
